import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { Layout } from 'components/global'
import { media } from 'utils/media'

const SuccessBody = styled.div`
  width: 100%;
  background-color: var(--darkGrey);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    width: 90%;
    margin: var(--auto);
    text-align: center;
    @media ${media.md} {
      width: 65%;
      h1 {
        font-size: 3rem;
      }
    }
    h1,
    p {
      color: var(--offWhite);
    }
    p {
      margin: 1rem auto 0 auto;
      max-width: 750px;
    }
    button {
      margin-top: 1rem;
      max-width: 200px;
      span {
        font-weight: 800;
      }
    }
  }
  @media ${media.md} {
    height: 100vh;
  }
`
const Success = ({ location }) => (
  <Layout $noSignup>
    <SuccessBody>
      <div>
        {location?.state ? (
          <h1>Thank you {location.state.name}</h1>
        ) : (
          <h1>Thank you</h1>
        )}
        <p>Please keep an eye on your inbox for our latest updates.</p>
        <Link to="/">
          <button className="btn btn-main" type="button">
            <span>Home</span>
          </button>
        </Link>
      </div>
    </SuccessBody>
  </Layout>
)

export default Success
